import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import {
  TermsAndConditions,
  Eligibility,
  Details,
  Location,
  Description,
  Banking,
  Contact,
  Dialog,
  CheckmarkIcon
} from '../shared'
import { SaveCharityButton, savedCharityKey } from './SaveCharityButton'
import { logError, removeItemFromWebStorage } from '../../lib'
import { submitCharity, fetchCharity, updateCharity } from '../../services'
import logoPlaceholder from '../../images/logo-placeholder.svg'
import brandingLogos from '../../images/co-branding-logos.svg'

const charityIdInit = uuidv4()

// These are only for UI purposes and are not utilised in the backend
const uiFieldsInit: CharityUiFields = {
  abnFileName: '',
  incorporationFileName: '',
  localGroupNameFileName: '',
  organizationLogoFileName: '',
  bankDetailsFileName: '',
  organizationLogoUrl: logoPlaceholder,
  abnBelongsToParent: false,
  hasAcceptedTos: false,
  operatesInNSW: false
}

const organizationInit: Organization = {
  name: '',
  websiteUrl: '',
  organizationAbn: '',
  operatingState: '',
  establishedDate: new Date(),
  legalStructure: 'NON_PROFIT_COMPANY',
  legalStructureDetails: '',
  geoFootprint: 'NATIONAL',
  incorporationNumber: '',
  displayName: '',
  localGroupName: '',
  streetAddress: '',
  postCode: '',
  suburb: '',
  benefit: '',
  description: ''
}

const bankingDataInit: BankingData = {
  accountName: '',
  accountBsb: '',
  accountNumber: '',
  dgrStatus: ''
}

const primaryContactInit: PrimaryContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

const secondaryContactInit: SecondaryContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

const Form = styled.form`
  :invalid > aside > button:last-child {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const CharityForm = () => {
  const history = useHistory()
  const { charityId: pathCharityId } = useParams<{ charityId: string }>()
  const [charityId, setCharityId] = useState(pathCharityId || charityIdInit)
  const [organization, setOrganization] = useState<Organization>(organizationInit)
  const [bankingData, setBankingData] = useState<BankingData>(bankingDataInit)
  const [primaryContact, setPrimaryContact] = useState<PrimaryContact>(primaryContactInit)
  const [secondaryContact, setSecondaryContact] = useState<SecondaryContact>(secondaryContactInit)
  const [uiFields, setUiFields] = useState(uiFieldsInit)
  const [fetchRequestStatus, setFetchRequestStatus] = useState<RequestStatus>('initial')
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [charityReference, setCharityReference] = useState<Charity | null>(null)

  const _fetchCharity = useCallback(async charityId => {
    try {
      setFetchRequestStatus('loading')

      const charity = await fetchCharity(charityId)

      setCharityReference(charity)
      setCharityId(charity.id)
      setOrganization(charity.organization)
      setBankingData(charity.bankingData)
      setPrimaryContact(charity.primaryContact)
      setSecondaryContact(charity.secondaryContact)
      setUiFields({
        ...uiFieldsInit,
        operatesInNSW: true,
        abnBelongsToParent: charity.organization.localGroupName ? true : false
      })

      setFetchRequestStatus('success')
    } catch (error: any) {
      logError(new Error('Failed to fetch existing campaign'), error)
      setFetchRequestStatus('failed')
    }
  }, [])

  const onSubmit = (evt: any) => {
    evt.preventDefault()

    if (pathCharityId) {
      return updateExistingCharity()
    } else {
      return createCharity()
    }
  }

  const createCharity = async () => {
    try {
      setSubmitRequestStatus('loading')

      await submitCharity({
        id: charityId,
        organization,
        bankingData,
        primaryContact,
        secondaryContact
      })

      removeItemFromWebStorage(savedCharityKey)

      setSubmitRequestStatus('success')
    } catch (error: any) {
      setSubmitRequestStatus('failed')
      logError(new Error('Failed to create new charity'), error)
    }
  }

  const updateExistingCharity = async () => {
    try {
      setSubmitRequestStatus('loading')

      await updateCharity({
        id: charityId,
        organization,
        bankingData,
        primaryContact,
        secondaryContact
      })

      removeItemFromWebStorage(savedCharityKey)

      setSubmitRequestStatus('success')
    } catch (error: any) {
      setSubmitRequestStatus('failed')
      logError(new Error('Failed to update charity'), error)
    }
  }

  useEffect(() => {
    if (pathCharityId) {
      _fetchCharity(charityId)
    }
  }, [_fetchCharity, charityId, pathCharityId])

  const charityHasChanges = useMemo(() => {
    if (!charityReference || !pathCharityId) {
      return false
    }

    return (
      JSON.stringify({
        organization: charityReference.organization,
        bankingData: charityReference.bankingData,
        primaryContact: charityReference.primaryContact,
        secondaryContact: charityReference.secondaryContact
      }) ===
      JSON.stringify({
        organization,
        bankingData,
        primaryContact,
        secondaryContact
      })
    )
  }, [charityReference, pathCharityId, organization, bankingData, primaryContact, secondaryContact])

  return fetchRequestStatus === 'loading' ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" data-testid="loading" />
    </div>
  ) : fetchRequestStatus === 'failed' ? (
    <h1 className="centerAbsolute alert alert-danger">Something went wrong. Please try again later</h1>
  ) : (
    <Form onSubmit={onSubmit} className="max-w-screen-2xl mx-auto my-md grid grid-cols-12 gap-x-md animate-fadeIn">
      <div className="col-span-8">
        <div className="space-y-md">
          {!pathCharityId && <TermsAndConditions uiFields={uiFields} setUiFields={setUiFields} />}
          <Eligibility
            uiFields={uiFields}
            setUiFields={setUiFields}
            organization={organization}
            setOrganization={setOrganization}
          />
          <Details
            charityId={charityId}
            uiFields={uiFields}
            setUiFields={setUiFields}
            organization={organization}
            setOrganization={setOrganization}
          />
          <Location organization={organization} setOrganization={setOrganization} />
          <Description
            charityId={charityId}
            uiFields={uiFields}
            setUiFields={setUiFields}
            organization={organization}
            setOrganization={setOrganization}
          />
          <Banking
            charityId={charityId}
            uiFields={uiFields}
            setUiFields={setUiFields}
            bankingData={bankingData}
            setBankingData={setBankingData}
          />
          <Contact
            primaryContact={primaryContact}
            setPrimaryContact={setPrimaryContact}
            secondaryContact={secondaryContact}
            setSecondaryContact={setSecondaryContact}
          />
        </div>
      </div>

      <div className="col-span-4">
        <aside className="card flex flex-col p-lg space-y-lg items-start">
          <div className="flex justify-between space-x-md">
            {pathCharityId ? (
              <h1 className="text-xl">Edit charity</h1>
            ) : (
              <h1 className="text-xl">Fundraising through the Return and Earn app</h1>
            )}
            <img src={brandingLogos} alt="return&earn tomra brand logos" className="max-w-12" />
          </div>

          {pathCharityId ? (
            <p className="prose">
              You can update your information in each of the sections to the left. Press "Update" when you're ready to
              update your information.
            </p>
          ) : (
            <p className="prose">
              Go through each section to the left to complete your application. Press "Submit" when you're ready to
              submit, or "Save progress" if you want to save and continue your application at a later time.
            </p>
          )}

          <div className="flex justify-end space-x-md w-full">
            {!pathCharityId && (
              <SaveCharityButton
                charityId={charityId}
                uiFields={uiFields}
                organization={organization}
                bankingData={bankingData}
                primaryContact={primaryContact}
                secondaryContact={secondaryContact}
                setCharityId={setCharityId}
                setUiFields={setUiFields}
                setOrganization={setOrganization}
                setBankingData={setBankingData}
                setPrimaryContact={setPrimaryContact}
                setSecondaryContact={setSecondaryContact}
              />
            )}

            <button
              type="submit"
              className="btn btn-primary-dark"
              disabled={submitRequestStatus === 'loading' || charityHasChanges}
              aria-disabled={submitRequestStatus === 'loading'}
            >
              {submitRequestStatus === 'loading' ? (
                <div className="loadingSpinner" aria-label="Please wait" />
              ) : (
                <span>{pathCharityId ? 'Update' : 'Submit'}</span>
              )}
            </button>
          </div>

          {submitRequestStatus === 'failed' && (
            <p className="text-red text-center font-bold mb-md">
              Something went wrong. Please try submitting again shortly. If the issue persists, please contact us at
              donations.nsw@tomra.com
            </p>
          )}

          {submitRequestStatus === 'success' && pathCharityId ? (
            <Dialog>
              <div className="card flex flex-col items-center p-xl animate-fadeIn">
                <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
                <h1 className="text-xl">Excellent!</h1>
                <p className="my-lg">Your changes has been submitted for review.</p>

                <button className="btn w-full" onClick={() => history.push(`/`)}>
                  Ok
                </button>
              </div>
            </Dialog>
          ) : submitRequestStatus === 'success' ? (
            <Dialog>
              <div className="card flex flex-col items-center p-xl animate-fadeIn">
                <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
                <h1 className="text-xl">Almost there!</h1>
                <p className="my-lg">Now let's add a campaign.</p>
                <button
                  className="btn btn-primary-dark w-full"
                  onClick={() => history.push(`/charity/${charityId}/campaign`)}
                >
                  Continue
                </button>
              </div>
            </Dialog>
          ) : null}
        </aside>
      </div>
    </Form>
  )
}
