import React, { useState } from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import { FileUpload, Label, Accordion } from '../'
import { logError } from '../../../lib'
import { uploadFile } from '../../../services'

const RadioContainer = styled.div`
  > div {
    display: flex;
    align-items: center;

    input {
      margin-right: var(--spacing-md);
    }
  }

  hr {
    border: 1px solid var(--colors-fog);
  }
`

type Props = {
  charityId: string
  uiFields: CharityUiFields
  setUiFields: (uiFields: CharityUiFields) => void
  bankingData: BankingData
  setBankingData: (bankingData: BankingData) => void
}

export const Banking = ({ charityId, uiFields, setUiFields, bankingData, setBankingData }: Props) => {
  const [bankDetailsRequestStatus, setBankDetailsRequestStatus] = useState<RequestStatus>('initial')

  const hasRestrictedDgr =
    bankingData.dgrStatus === 'RESTRICTED' ||
    bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_YES' ||
    bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_NO'

  const onBankDetailsUpload = async (bankDetailsFile: File) => {
    setBankDetailsRequestStatus('loading')

    try {
      await uploadFile(bankDetailsFile, charityId, 'ProofOfBankDetails')
      setBankDetailsRequestStatus('initial')
      setUiFields({ ...uiFields, bankDetailsFileName: bankDetailsFile.name })
    } catch (error: any) {
      setBankDetailsRequestStatus('failed')
      logError(new Error('Failed to upload ProofOfBankDetails file'), error)
    }
  }

  return (
    <Accordion title="Finance" locked={bankDetailsRequestStatus === 'loading'}>
      <div>
        <p>We do not share any of your finance details with a third party.</p>
        <p>Your bank details are secured and only used to make payment of the donations.</p>
      </div>

      <div>
        <Label htmlFor="accountName">Account name</Label>

        <input
          type="text"
          id="accountName"
          value={bankingData.accountName}
          onChange={evt => setBankingData({ ...bankingData, accountName: evt.target.value })}
          required
        />
      </div>

      <div>
        <Label htmlFor="accountBsb">BSB</Label>

        <input
          type="text"
          id="accountBsb"
          value={bankingData.accountBsb}
          onChange={evt => setBankingData({ ...bankingData, accountBsb: evt.target.value })}
          pattern="^\d{6}$"
          title="6 digits"
          required
        />
      </div>

      <div>
        <Label htmlFor="accountNumber">Account number</Label>

        <input
          type="text"
          id="accountNumber"
          value={bankingData.accountNumber}
          onChange={evt => setBankingData({ ...bankingData, accountNumber: evt.target.value })}
          pattern="^\d{1,9}$"
          title="Up to 9 digits"
          required
        />
      </div>

      <Route exact path="/charity">
        <FileUpload
          id="bankDetailsFile"
          uploadedFileName={uiFields.bankDetailsFileName}
          onChange={onBankDetailsUpload}
          requestStatus={bankDetailsRequestStatus}
          acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
        >
          <div>
            <p>Please provide 1 proof of bank details in 1 of the following 3 formats:</p>

            <ul className="list-disc list-inside my-lg">
              <li>Pre-printed bank deposit slip</li>
              <li>Bank statement header</li>
              <li>Bank information in PDF format on company letterhead signed by management</li>
            </ul>
          </div>
        </FileUpload>
      </Route>

      <RadioContainer
        id="dgrStatus"
        className="space-y-md"
        onClick={(evt: any) => {
          if (evt.target.value) {
            setBankingData({ ...bankingData, dgrStatus: evt.target.value })
          }
        }}
      >
        <Label tooltipContent="If unsure about you DGR endorsement, please check with your tax advisor or contact the ATO.">
          Which of the following statements applies to your Deductible Gift Registration (DGR) status with the
          Australian Tax Office (ATO)?
        </Label>

        <div>
          <input
            type="radio"
            className="radio"
            id="dgrStatus1"
            value="NONE"
            name="dgrStatus"
            checked={bankingData.dgrStatus === 'NONE'}
            required
            readOnly
          />
          <label htmlFor="dgrStatus1">My organisation doesn't have the DGR endorsement</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="dgrStatus2"
            value="UNRESTRICTED"
            name="dgrStatus"
            checked={bankingData.dgrStatus === 'UNRESTRICTED'}
            required
            readOnly
          />
          <label htmlFor="dgrStatus2">My organisation has unrestricted DGR endorsement with the ATO</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="dgrStatus3"
            value="RESTRICTED"
            name="dgrStatus"
            checked={hasRestrictedDgr}
            required
            readOnly
          />
          <label htmlFor="dgrStatus3">My organisation has restricted DGR endorsement with the ATO</label>
        </div>

        {hasRestrictedDgr && (
          <React.Fragment>
            <hr />

            <Label>
              Can you please confirm the funds raised through Return and Earn will be spent exclusively on DGR related
              activities?
            </Label>

            <div>
              <input
                type="radio"
                className="radio inline-block mr-md"
                id="subDgrStatus1"
                value="RESTRICTED_DGR_ACTIVITIES_YES"
                name="subDgrStatus"
                checked={bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_YES'}
                required
                readOnly
              />
              <label htmlFor="subDgrStatus1">Yes</label>
            </div>

            <div>
              <input
                type="radio"
                className="radio inline-block mr-md"
                id="subDgrStatus2"
                value="RESTRICTED_DGR_ACTIVITIES_NO"
                name="subDgrStatus"
                checked={bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_NO'}
                required
                readOnly
              />
              <label htmlFor="subDgrStatus2">No</label>
            </div>
          </React.Fragment>
        )}
      </RadioContainer>
    </Accordion>
  )
}
