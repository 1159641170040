import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  p,
  li,
  span,
  td {
    max-width: 65ch;
  }

  > ol > li:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }

  > ol > li > ol {
    margin-top: var(--spacing-lg);
    margin-left: var(--spacing-lg);
  }

  > ul > li > ol,
  > ul > li > p {
    margin: var(--spacing-lg) 0 var(--spacing-lg) var(--spacing-lg);
  }

  table {
    max-width: 100rem;
  }
`

export const TermsAndConditions = () => (
  <Container className="card flex flex-col m-lg p-lg list-inside space-y-xl">
    <h1 className="text-xl">Return and Earn app donation partner agreement (NSW)</h1>

    <p>
      TICKING THE "<span className="underline">I HAVE READ AND AGREE TO THE TERMS AND CONDITIONS</span>" BOX CONSTITUTES
      ACCEPTANCE OF ALL TERMS AND CONDITIONS OF THIS AGREEMENT BY THE ENTITY APPLYING FOR REGISTRATION AS A RETURN AND
      EARN APP DONATION PARTNER (THE APPLICANT). THE INDIVIDUAL CLICKING THE BUTTON REPRESENTS THAT HE OR SHE IS DULY
      AUTHORISED TO ENTER INTO THIS AGREEMENT ON BEHALF OF THE APPLICANT. ACCEPTANCE OF ALL OF THE TERMS AND CONDITIONS
      OF THIS AGREEMENT IS A PRECONDITION TO PARTICIPATION IN THE RETURN AND EARN APP DONATION PARTNER PROGRAM, AND
      ACCEPTANCE IS LIMITED TO THESE TERMS. IF TOMRA ACCEPTS THE APPLICANT'S APPLICATION TO BECOME A RETURN AND EARN APP
      DONATION PARTNER, THE EFFECTIVE DATE OF THIS AGREEMENT WILL BE THE DATE THAT TOMRA CONFIRMS IS THE APPLICANT'S
      DATE OF REGISTRATION AS A RETURN AND EARN APP DONATION PARTNER.
    </p>

    <hr />

    <h2 className="text-lg font-bold">INTRODUCTION</h2>

    <ol className="list-uppercase list-inside">
      <li>
        TOMRA has been appointed to operate reverse vending machines (RVMs) as collection points for the NSW Container
        Deposit Scheme.
      </li>

      <li>
        The Return And Earn App gives RVM users the option to donate Container Refund Amounts to charities or other
        not-for-profit bodies nominated and approved by TOMRA.
      </li>

      <li>
        Subject to accepting the terms and conditions of this Agreement, an applicant body that meets the Eligibility
        Criteria may be registered by TOMRA as a Return And Earn App Donation Partner.
      </li>

      <li>
        Registered Return And Earn App Donation Partners may have their fundraising campaigns featured among the
        campaigns appearing from time to time on the Return And Earn App.
      </li>

      <li>
        TOMRA will collect and remit to Return And Earn App Donation Partners all donations of Container Refund Amounts
        made in support of their featured campaigns by RVM users via the Return And Earn App.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">TERMS AND CONDITIONS OF AGREEMENT</h2>

    <ol className="list-decimal list-inside">
      <li>
        <b>DEFINITIONS</b>
        <p>Capitalised terms used in this Agreement have the meaning given in the Definitions Schedule below.</p>
      </li>

      <li>
        <b>REGISTRATION AS A Return And Earn APP DONATION PARTNER</b>
        <p>
          Subject to the terms of this Agreement, if TOMRA accepts the Applicant's application for registration as a
          Return And Earn App Donation Partner, the Applicant may from time to time create Featured Campaigns to which
          Container Refund Amounts can be donated by RVM users who have the Return And Earn App.
        </p>
      </li>

      <li>
        <b>ELIGIBILITY CRITERIA</b>
        <p>
          The Applicant warrants to TOMRA that it meets the Eligibility Criteria (see Appendix A). If the Applicant is
          accepted and registered by TOMRA as a Return And Earn App Donation Partner, it must promptly inform TOMRA if
          at any time during the Term it ceases to meet the Eligibility Criteria.
        </p>
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">FEATURED CAMPAIGNS</h2>

    <ol className="list-lowercase list-inside">
      <li>
        If the Applicant is accepted and registered by TOMRA as a Return And Earn App Donation Partner, it may then be
        permitted by TOMRA to conduct fundraising campaigns, to be featured on the Return And Earn App.
      </li>

      <li>
        The Applicant may propose a Featured Campaign to TOMRA by providing a description of the objectives of the
        campaign, a target campaign fundraising amount, a name for the campaign and any other details that TOMRA may
        require for publication on the Return And Earn App and in other Scheme publicity materials.
      </li>

      <li>
        Each Featured Campaign shall be limited to between 3 and 12 months' duration, with a start and end date as
        agreed between the Applicant and TOMRA.
      </li>

      <li>
        However, despite any prior agreement as to the duration of any Featured Campaign, TOMRA reserves the right to
        delay the start date of any Featured Campaign, to temporarily suspend any Featured Campaign, to extend any
        Featured Campaign or bring it to an early close, in TOMRA's absolute discretion.
      </li>

      <li>
        Funds raised in a Featured Campaign must only be applied for purposes consistent with the Applicant's charitable
        or public benevolent purposes and the campaign objectives as described to TOMRA in the Applicant's proposal for
        the Featured Campaign.
      </li>

      <li>
        A Return And Earn App Donation Partner may have only one Featured Campaign current at any one time. TOMRA may
        from time to time set limits on the frequency or duration of the Applicant's Featured Campaigns, or minimum time
        intervals between the end of one Featured Campaign and the beginning of the next Featured Campaign.
      </li>

      <li>
        The only reporting of amounts donated to a Featured Campaign will be the cumulative balance information
        available to the Applicant on the Return And Earn App. TOMRA may provide additional reporting in its absolute
        discretion.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">PAYMENT OF DONATION AMOUNTS</h2>

    <ol className="list-lowercase list-inside">
      <li>
        The Applicant must provide TOMRA with particulars of the Applicant's nominated bank account for receipt of
        donation amounts, when applying for registration or renewed registration as a Return And Earn App Donation
        Partner. The Applicant acknowledges the bank account must be an Australian bank account in the registered name
        of the Applicant (excluding NSW Government Schools).
      </li>

      <li>
        TOMRA will pay to the Applicant's nominated bank account the total of the amounts donated to a Featured Campaign
        in each week of the Featured Campaign, within 7 days after the end of that week.
      </li>

      <li>
        The Applicant will provide TOMRA with a receipt acknowledging each payment made by TOMRA pursuant to this clause
        within four weeks of the end of the relevant Featured Campaign.
      </li>

      <li>
        If it is found by TOMRA that any donation amount that it previously paid was inaccurately calculated or
        otherwise overpaid or underpaid by mistake, the parties will make adjustments to payments and receipts as
        necessary to correct the previous inaccurate or mistaken payment and any receipt based on it.
      </li>

      <li>
        Prior to each Featured Campaign, the Applicant must confirm to TOMRA the details of the bank account to which
        payments to the Applicant should be made.
      </li>

      <li>
        If TOMRA is unable to make a payment because the Applicant has supplied incorrect or out-of-date particulars of
        the Applicant's bank account, TOMRA will make reasonable efforts to contact the Applicant to obtain the correct
        and up-to-date particulars needed to make the payment to the Applicant. If despite its efforts TOMRA does not
        receive the correct and up-to-date particulars of the Applicant's bank account within 6 months of the due date
        for the payment (or any longer period required by law), TOMRA may in its absolute discretion at any time
        thereafter donate the payment amount on behalf of the Applicant to any registered charity that TOMRA may choose.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">LICENCE TO DISPLAY DONATION PARTNER NAME AND BRANDING</h2>

    <ol className="list-lowercase list-inside">
      <li>
        The Applicant grants TOMRA a non-exclusive, royalty-free licence for the Term to display and publish the
        Approved Materials (see Appendix B) on the graphical user interface of the Return And Earn App and in publicity
        relating to the Applicant's participation as a donation partner in the Scheme.
      </li>

      <li>The Applicant agrees to supply the Approved Materials in a timely manner when requested by TOMRA.</li>

      <li>
        The Applicant further agrees to comply with any reasonable request to supply additional marketing materials
        along with permission to use such additional marketing materials, if requested by TOMRA, the State or the Scheme
        Coordinator for the purpose of advertising and publicising the Applicant's inclusion as a Scheme donation
        recipient during the Term.
      </li>

      <li>
        TOMRA may sub-licence to other Scheme stakeholders the right to display and publish Approved Materials and
        additional materials referred to in this clause, for the purpose of advertising and publicising the Applicant's
        participation as a donation partner in the Scheme.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">PROMOTION BY DONATION PARTNER</h2>

    <p>Prior to and during each Featured Campaign, the Applicant will use reasonable endeavours to:</p>

    <ol className="list-lowercase list-inside">
      <li>
        raise public awareness of the Applicant's status as a potential recipient of donations by RVM users via the
        Return And Earn App; and
      </li>
      <li>
        encourage members of the public to recycle drink containers through the RVMs and to use the Return And Earn App
        to donate the proceeds to the Applicant's Featured Campaign.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">DONATION RECEIPTS</h2>

    <ol className="list-lowercase list-inside">
      <li>
        The Applicant authorises TOMRA to make available to RVM users, on the Applicant's behalf, receipts or other
        evidence of donations of Container Refund Amounts they make to the Applicant.
      </li>

      <li>
        If the Applicant is a deductible gift recipient, TOMRA will make available receipts or other evidence of
        donations in a form that complies with the requirements set out in the Income Tax Assessment Act 1997 (Cth) for
        receipts issued by organisations with deductible gift recipient status, including:
        <ol className="list-decimal list-inside">
          <li>the name of the Applicant;</li>
          <li>the Australian Business Number (if applicable) of the Applicant; </li>
          <li>a note stating the amount is a gift;</li>
          <li>the amount received from the donor; and </li>
          <li>the date of issue.</li>
        </ol>
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">APPLICANT'S ACKNOWLEDGEMENTS</h2>

    <p>The Applicant acknowledges that:</p>

    <ol className="list-lowercase list-inside">
      <li>
        its eligibility to be nominated by TOMRA as a donation recipient under this Agreement will continue only for so
        long as it remains registered by TOMRA as a Return And Earn App Donation Partner;
      </li>

      <li>
        its nomination by TOMRA as a donation recipient under this Agreement will only operate for the duration of each
        Featured Campaign, which will be limited to a period of 3 to 12 months unless extended by TOMRA in its sole
        discretion;
      </li>

      <li>
        its nomination by TOMRA as a donation recipient under this Agreement will not be an exclusive nomination (as RVM
        and Return And Earn App users will be given a choice to direct their donations to other recipients);
      </li>

      <li>
        certain RVMs may from time to time be dedicated exclusively to one or more specific charities or not-for-profit
        organisations, such that users of those RVMs do not have the option of directing their Container Refund Amount
        donation from those RVMs to the Applicant; and
      </li>

      <li>
        TOMRA gives no undertakings to the Applicant about the position of the Applicant or its Featured Campaign on the
        graphical user interface of the Return And Earn App viz a viz other donees and Featured Campaigns that appear
        there at the same time.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">DELAYS AND PERFORMANCE FAILURES</h2>

    <ol className="list-lowercase list-inside">
      <li>
        TOMRA will use all reasonable endeavours but does not guarantee to ensure that the either the RVMs or the Return
        And Earn App are installed and operating in the manner contemplated by this Agreement, either on the Start Date
        or at all times during the Term.
      </li>

      <li>
        The Applicant acknowledges that the installation, commissioning and operation of the RVMs, the Return And Earn
        App and related information and payment systems may be subject to technical and logistical problems.
      </li>

      <li>
        If TOMRA is unable to perform or is delayed in performing any of its obligations under this Agreement as a
        result of an equipment or systems failure, or any event outside of its reasonable control, the affected
        obligation will be suspended for so long as the failure or event continues.
      </li>

      <li>
        To the extent permitted by law, TOMRA's liability to the Applicant under or in respect of this Agreement whether
        in contract, tort (including negligence), statute or any other cause of action is limited to direct loss in the
        amount of any donation sums that TOMRA has failed to pay to or as directed by the Applicant in accordance with
        this Agreement.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">DURATION OF AGREEMENT</h2>

    <p>
      This Agreement will continue to operate for so long as the Applicant remains registered as a Return And Earn App
      Donation Partner, subject to early termination under clause 13.
    </p>

    <h2 className="text-lg font-bold">REGISTRATION PERIOD</h2>

    <ol className="list-lowercase list-inside">
      <li>
        The registration of the Applicant as a Return And Earn App Donation Partner will at all times be and remain at
        TOMRA's sole discretion.
      </li>

      <li>
        TOMRA may from time to time set an expiry date on the registration of the Applicant with prior notice of one
        month or more.
      </li>

      <li>
        TOMRA may from time to time require the Applicant to provide updated registration details (including bank
        account and eligibility information) to ensure they remain current and that the Applicant continues to meet the
        Eligibility Criteria.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">TERMINATION</h2>

    <ol className="list-lowercase list-inside">
      <li>
        TOMRA may terminate this Agreement and the registration of the Applicant as a Return And Earn App Donation
        Partner at any time by notice to the Applicant (and with or without any period of prior notice), if:
        <ol className="list-decimal list-inside">
          <li>
            TOMRA reasonably forms the view that the Applicant no longer meets the Eligibility Criteria or that a
            Material Adverse Event has occurred in relation to the Applicant;
          </li>

          <li>
            TOMRA ceases for any reason to be an authorised operator of RVMs under the Scheme or ceases to offer the
            Return And Earn App as a method of donating Container Refund Amounts; or
          </li>

          <li>
            the State directs TOMRA to terminate the participation of the Applicant as a recipient of Scheme-related
            donations.
          </li>
        </ol>
      </li>

      <li>
        The Applicant may terminate this Agreement and the registration of the Applicant as a Return And Earn App
        Donation Partner at any time by notice to TOMRA (and with or without any period of prior notice), if:
        <ol className="list-decimal list-inside">
          <li>
            the Applicant reasonably forms the view that a Material Adverse Event has occurred in relation to TOMRA;
          </li>

          <li>
            TOMRA extends the duration of a Featured Campaign of the Applicant in accordance with clause 4 and the
            Donation Partner does not wish to continue the Featured Campaign; or
          </li>

          <li>
            TOMRA transfers or proposes to transfer its rights and obligations to a third party in accordance with
            clause 15 and the Applicant does not wish to partner with the third party.
          </li>
        </ol>
      </li>

      <li>
        Any termination under this clause will not affect any donation entitlements of the Applicant that have accrued
        up to the termination date.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">DATA PROTECTION</h2>

    <ol className="list-lowercase list-inside">
      <li>
        TOMRA will comply with applicable privacy laws and its published privacy policy from time to time, in relation
        to the collection, handling, storage and use of the personal information of users of RVMs and the Return And
        Earn App.
      </li>

      <li>
        TOMRA will ensure that all data received and processed by the Return And Earn App is hosted within Australia.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">ASSIGNMENT</h2>

    <p>
      This Agreement is personal to the Applicant and it may not transfer any of the rights and benefits conferred on it
      under this Agreement. TOMRA may transfer its rights and obligations under this Agreement by assignment or novation
      to the State or any other person who may from time to time be appointed to operate the RVMs as Scheme collection
      points.
    </p>

    <hr />

    <h2 className="text-lg font-bold">GENERAL PROVISIONS</h2>

    <ul>
      <li>
        GST
        <ol className="list-lowercase list-inside">
          <li>
            If GST is imposed or levied in respect of any supply by a party under or in accordance with this Agreement
            the party making the supply may recover the GST Amount from the party receiving the supply in addition to
            the Consideration. The party making the supply will provide such invoices to the party receiving the supply
            as are required pursuant to GST Legislation, provided that this does not apply where TOMRA is the party
            receiving the supply and has elected to issue a RCTI in relation to that supply.
          </li>

          <li>
            TOMRA may issue a RCTI to the Applicant in respect of any supply made to TOMRA by the Applicant in
            connection with this Agreement. The Applicant must not issue a tax invoice in respect of any supply for
            which TOMRA has elected to issue a RCTI.
          </li>

          <li>
            Each party will notify the other if it ceases to be registered for GST. TOMRA will notify the Applicant if
            it ceases to satisfy the requirements of any determination made under GST Legislation with respect to the
            issue of RCTIs.
          </li>

          <li>
            TOMRA may at any time withdraw an election to issue RCTIs by notice to the Applicant, in respect of
            specified supplies, in which case the Applicant will be required to issue tax invoices to TOMRA in respect
            of those supplies.
          </li>
        </ol>
      </li>

      <li>
        Whole agreement
        <p>This Agreement comprises the whole of the agreement between the parties.</p>
      </li>

      <li>
        Notices
        <ol className="list-lowercase list-inside">
          <li>
            Any notice to be given under this Agreement by one of the parties to the other must be in writing and is
            given for all purposes by e-mail.
          </li>

          <li>
            Any notice to the Applicant may be sent to the e-mail address notified by the Applicant in its registration
            details most recently provided to TOMRA at the time of the notice. Notices to TOMRA may be sent to
            donations.nsw@tomra.com.
          </li>

          <li>
            Any e-mailed notice given in accordance with this Agreement will be deemed to have been duly served when
            transmitted by the sender unless the sender receives a message from its or the recipient's internet service
            provider or mail server indicating that it has not been successfully transmitted.
          </li>

          <li>
            A party may at any time change its e-mail address for the service of notices under this Agreement by giving
            written notice to the other party.
          </li>
        </ol>
      </li>

      <li>
        Relationship
        <p>
          The parties are independent contractors. This Agreement is not intended and will not be taken to constitute
          any partnership, agency, trust or joint venture relationship between the parties.
        </p>
      </li>

      <li>
        Governing law
        <p>This Agreement is governed by the law in force in the State of New South Wales.</p>
      </li>

      <li>
        Giving effect to this Agreement
        <p>
          Each party must do anything (including execute any document) that the other party may reasonably require to
          give full effect to this Agreement.
        </p>
      </li>
    </ul>

    <hr />

    <h1 className="text-xl">DEFINITIONS SCHEDULE (Definitions and interpretation)</h1>
    <p>In this Agreement, unless the context otherwise requires:</p>

    <span>
      <b>Approved Materials</b> means the logos, text and other materials set out or described in Appendix B.
    </span>

    <span>
      <b>Business Day</b> means a day other than a Saturday, Sunday or public or bank holiday in New South Wales.
    </span>

    <span>
      <b>Consideration</b> means any amount or consideration payable or to be provided pursuant to any provision of this
      Agreement.
    </span>

    <span>
      <b>Container Refund Amount</b> means the refund amount payable for a container deposited at an RVM, as prescribed
      under the regulations governing the Scheme from time to time.
    </span>

    <span>
      <b>GST</b> means any form of goods and services tax or similar value added tax.
    </span>

    <span>
      <b>GST Amount</b> means the Consideration (after deducting the GST Exempt Component) multiplied by the GST Rate.
    </span>

    <span>
      <b>GST Exempt Component</b> means any part of the Consideration which solely relates to a supply that is free or
      exempt from the imposition of GST.
    </span>

    <span>
      <b>GST Legislation</b> means A New Tax System (Goods and Services Tax) Act 1999(Cth) and any other legislation or
      regulation which imposes, levies, implements or varies a GST or any applicable rulings issued by the Commissioner
      of Taxation.
    </span>

    <span>
      <b>GST Rate</b> means the rate at which GST Legislation from time to time imposes or levies GST on the relevant
      supply
    </span>

    <span>
      <b>Material Adverse Event</b> means an event, matter, circumstance, public allegation or controversy relating to a
      party occurs or becomes known to the other party and, in the other party's reasonable opinion, the event, matter,
      circumstance, public allegation or controversy has resulted in or could reasonably be expected to result in a
      material adverse effect on the reputation or public perceptions of either party or the Scheme.
    </span>

    <span>
      <b>RCTI</b> means a recipient created tax invoice as defined in the GST Legislation.
    </span>

    <span>
      <b>RVM</b> has the meaning given in paragraph (A) of the Introduction to this Agreement.
    </span>

    <span>
      <b>Eligibility Criteria</b> means the criteria set out or referred to in Appendix A.
    </span>

    <span>
      <b>Featured Campaign</b> means a campaign by a Return And Earn App Donation Partner to raise donated funds for the
      myTOMRA App Donation Partner to be applied towards a purpose described in the Return And Earn App Donation
      Partner's proposal to TOMRA and summarised on the Return And Earn App.
    </span>

    <span>
      <b>Return And Earn App</b> means the application for mobile devices made available by TOMRA to users of RVMs,
      including any modification or new version of that application that TOMRA may issue from time to time.
    </span>

    <span>
      <b>Return And Earn App Donation Partner</b> means an entity that is registered by TOMRA as eligible and permitted
      to receive donations of Container Refund Amounts for its Featured Campaigns, by direction from RVM users made via
      the Return And Earn App.
    </span>

    <span>
      <b>Scheme</b> means the Container Deposit Scheme established under Part 5 of the Waste Avoidance and Resource
      Recovery Act 2001 (NSW).
    </span>

    <span>
      <b>State</b> means the Crown in right of the State of New South Wales.
    </span>

    <span>
      <b>Supply</b> includes supply as defined under GST Legislation.
    </span>

    <span>
      <b>Term</b> means the period of operation of this Agreement, as stated in clause 11.
    </span>

    <span>
      <b>TOMRA</b> means TOMRA Collection Pty Ltd (ABN 90 111 224 018) of Unit 2, 39 Herbert Street, St Leonards NSW
      2065.
    </span>

    <hr />

    <h2 className="text-lg font-bold">Interpretation</h2>

    <p>
      The following rules of interpretation apply to this Agreement unless the context indicates a contrary intention:
    </p>

    <ol className="list-lowercase list-inside">
      <li>
        A reference to any party includes that party's administrators, successors and permitted assigns, including any
        person taking by way of novation and, in the case of a trustee, includes any substituted or additional trustee;
      </li>

      <li>
        A reference to any document (including this Agreement) is to that document as varied, novated, ratified or
        replaced from time to time;
      </li>

      <li>
        A reference to any statute or to any statutory provision includes any statutory modification or re-enactment of
        it or any statutory provision substituted for it, and all ordinances, by-laws, regulations, rules and statutory
        instruments (however described) issued under it;
      </li>

      <li>
        Words importing the singular include the plural (and vice versa), and words indicating a gender include every
        other gender;
      </li>

      <li>
        References to parties, clauses, schedules, exhibits or annexures are references to parties, clauses, schedules,
        exhibits and annexures to or of this Agreement, and a reference to this Agreement includes any schedule,
        appendix, exhibit or annexure to this Agreement;
      </li>

      <li>
        Where a word or phrase is given a defined meaning, any other part of speech or grammatical form of that word or
        phrase has a corresponding meaning;
      </li>

      <li>
        The word <b>month</b> means a calendar month;
      </li>

      <li>
        The word <b>including</b> or words of like meaning are not words of limitation; and
      </li>

      <li>
        A reference to <b>$</b> or <b>dollar</b> is to Australian currency.
      </li>
    </ol>

    <h2 className="text-lg font-bold">APPENDIX A</h2>
    <h3>(Eligibility Criteria for Return and Earn App Donation Partners)</h3>

    <p>
      Organisations applying to become <b>Return and Earn App Donation Partners</b> must meet all of the following
      criteria:
    </p>

    <ol className="list-lowercase list-inside">
      <li>
        Fulfill at least one of the following eligibility criteria:
        <ol className="list-decimal list-inside">
          <li>
            Be currently registered as a charity in NSW (unless exempted under the Charitable Fundraising Act 1991); or
          </li>

          <li>Have Deductible Gift Status with the Australian Taxation Office; or</li>

          <li>Be a registered school (whether primary or secondary, government or private); or</li>
          <li>
            If a sporting club or other community, educational or environmental organisation, be incorporated as a
            not-for-profit organisation (either under the Associations Incorporation Act 2009 or as a company limited by
            guarantee) and further, if a sporting club, demonstrate an affiliation with a recognised state sporting
            organisation.
          </li>
        </ol>
      </li>

      <li>
        Be willing, with reasonable endeavours, to use its own networks and resources to promote awareness of its
        presence on the Return and Earn App to mutual advantage;
      </li>

      <li>Have been operating for at least two years;</li>

      <li>Be reputable and of good standing in the community;</li>

      <li>
        Not be a political party or an organisation associated with current political issues or political activism;
      </li>

      <li>Not be associated with gambling, wagering, tobacco or smoking as a primary source of their income;</li>

      <li>Not be associated with any illegal or criminal activity; and,</li>

      <li>
        Not be associated with any activity or publicity that could bring the Return & Earn scheme or any of its
        stakeholders into controversy or disrepute.
      </li>
    </ol>

    <hr />

    <h2 className="text-lg font-bold">APPENDIX B</h2>
    <h3>(Approved Materials)</h3>

    <table className="table">
      <thead>
        <tr>
          <th>Item</th>
          <th>Context / Use</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>"Display Name": brand name or commonly used name (if different from legal name)</td>
          <td>
            To appear on the 'Charities' pages on the Return and Earn App. TOMRA reserves the right to not use any name
            that is not the registered name of the organisation or a business name or trade mark owned by the
            organisation.
          </td>
        </tr>

        <tr>
          <td>Text Description of Donation Partner</td>
          <td>
            A text description of the Return and Earn App Donation Partner’s organisation of up to 500 characters that
            may be used on websites, press releases, fact sheets, the Return and Earn App and other publicly available
            information sources published by TOMRA or other stakeholders associated with the Scheme including but not
            limited to the State, TOMRA Cleanaway Pty. Ltd. and the Return and Earn website.
          </td>
        </tr>

        <tr>
          <td>Key Graphics Files(not compulsory)</td>
          <td>
            Graphics files that may include the Return and Earn App Donation Partner’s logo and other messaging and
            which can be used for the same purposes as described in the “Text Description” immediately above. (Not
            compulsory or required if your organisation does not have them or cannot easily make them available).
          </td>
        </tr>
      </tbody>
    </table>

    <p>TOMRA retains the right to approve or reject any names or images submitted to it as Approved Materials.</p>
  </Container>
)
