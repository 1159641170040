import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { CharitiesList } from './components/charities/CharitiesList'
import { CharityForm } from './components/charities/CharityForm'
import { CampaignForm } from './components/campaigns/CampaignForm'
import { authentication, getUserIdFromJwt } from './lib'
import { LogoutIcon } from './components/shared'
import tomraArrow from './images/tomra-arrow.png'

export class App extends React.Component {
  componentDidMount() {
    window.DD_RUM && window.DD_RUM.setUser({ id: getUserIdFromJwt() })
  }

  render() {
    return (
      <React.Fragment>
        <div className="navbar">
          <div className="max-w-screen-2xl mx-auto w-full flex items-center justify-between">
            <div className="flex items-center">
              <img src={tomraArrow} className="h-xl mr-md" alt="TOMRA logo" />
              <Link to="/">myTOMRA Charity</Link>
            </div>

            <button className="btn btn-icon" onClick={authentication.logout}>
              <LogoutIcon width="2rem" height="2rem" color="white" />
            </button>
          </div>
        </div>

        <Switch>
          <Route path="/charity/:charityId/campaign/:campaignId" component={CampaignForm} />
          <Route path="/charity/:charityId/campaign" component={CampaignForm} />
          <Route path="/charity/:charityId" component={CharityForm} />
          <Route path="/charity" component={CharityForm} />
          <Route component={CharitiesList} />
        </Switch>
      </React.Fragment>
    )
  }
}
