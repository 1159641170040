import React, { useState, useEffect } from 'react'
import { getItemFromWebStorage, setItemInWebStorage } from '../../lib'

export const savedCharityKey = 'charityApplication'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  organization: Organization
  bankingData: BankingData
  primaryContact: PrimaryContact
  secondaryContact: SecondaryContact
  setCharityId: (id: string) => void
  setUiFields: (uiFields: CharityUiFields) => void
  setOrganization: (organization: Organization) => void
  setBankingData: (bankingData: BankingData) => void
  setPrimaryContact: (primaryContact: PrimaryContact) => void
  setSecondaryContact: (secondaryContact: SecondaryContact) => void
}

export const SaveCharityButton = ({
  charityId,
  uiFields,
  organization,
  bankingData,
  primaryContact,
  secondaryContact,
  setCharityId,
  setUiFields,
  setOrganization,
  setBankingData,
  setPrimaryContact,
  setSecondaryContact
}: Props) => {
  const serialisedNewCharity = btoa(
    JSON.stringify({
      id: charityId,
      uiFields,
      organization,
      bankingData,
      primaryContact,
      secondaryContact
    })
  )
  const [serialisedSavedCharity, setSerialisedSavedCharity] = useState(serialisedNewCharity)

  useEffect(() => {
    getItemFromWebStorage(savedCharityKey).then(serialisedCharity => {
      if (serialisedCharity) {
        setSerialisedSavedCharity(serialisedCharity)

        const savedCharity = JSON.parse(atob(serialisedCharity))

        // Ignore saved charities from old UI
        if (savedCharity.hasAcceptedTos) {
          return
        }

        setCharityId(savedCharity.id)
        setUiFields(savedCharity.uiFields)
        setOrganization(savedCharity.organization)
        setBankingData(savedCharity.bankingData)
        setPrimaryContact(savedCharity.primaryContact)
        setSecondaryContact(savedCharity.secondaryContact)
      }
    })
  }, [setCharityId, setUiFields, setOrganization, setBankingData, setPrimaryContact, setSecondaryContact])

  const saveApplication = async () => {
    await setItemInWebStorage(savedCharityKey, serialisedNewCharity)
    setSerialisedSavedCharity(serialisedNewCharity)
  }

  return (
    <button
      className="btn"
      onClick={saveApplication}
      disabled={serialisedNewCharity === serialisedSavedCharity}
      aria-disabled={serialisedNewCharity === serialisedSavedCharity}
    >
      Save progress
    </button>
  )
}
