import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import { ImageUpload, Label, Accordion } from '../'
import { logError } from '../../../lib'
import { uploadFile } from '../../../services'
import { benefitToReadableString } from './enumsToReadableStrings'
import logoAppPreview from '../../../images/logoAppPreview.png'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  setUiFields: (uiFields: CharityUiFields) => void
  organization: Organization
  setOrganization: (organization: Organization) => void
}

export const Description = ({ charityId, uiFields, setUiFields, organization, setOrganization }: Props) => {
  const [organizationLogoRequestStatus, setOrganizationLogoRequestStatus] = useState<RequestStatus>('initial')

  const onOrganizationLogoUpload = async (organizationLogoBlob: Blob, organizationLogoFileName: string) => {
    setOrganizationLogoRequestStatus('loading')
    try {
      await uploadFile(organizationLogoBlob, charityId, 'OrganizationLogo')
      setOrganizationLogoRequestStatus('initial')
      setUiFields({
        ...uiFields,
        organizationLogoUrl: URL.createObjectURL(organizationLogoBlob),
        organizationLogoFileName
      })
    } catch (error: any) {
      setOrganizationLogoRequestStatus('failed')
      logError(new Error('Failed to upload OrganizationLogo file'), error)
    }
  }

  return (
    <Accordion title="About your organisation" locked={organizationLogoRequestStatus === 'loading'}>
      <div className="flex items-start">
        <div className="flex flex-col flex-1 space-y-lg">
          <div>
            <Label
              htmlFor="displayName"
              tooltipContent="The actual name as it will be shown in Return and Earn. Note: TOMRA reserves the right to not use any name that is not the registered name of the organisation or a business name or trademark owned by the organisation."
            >
              Display name
            </Label>

            <input
              type="text"
              id="displayName"
              value={organization.displayName}
              onChange={evt => setOrganization({ ...organization, displayName: evt.target.value })}
              required
            />
          </div>

          <div>
            <Label htmlFor="websiteUrl">Website url</Label>

            <input
              type="text"
              id="websiteUrl"
              value={organization.websiteUrl}
              pattern=".*\..*"
              onChange={evt => setOrganization({ ...organization, websiteUrl: evt.target.value })}
            />
          </div>

          <Route exact path="/charity">
            <div>
              <ImageUpload
                id="organizationLogo"
                uploadedFileName={uiFields.organizationLogoFileName}
                onChange={onOrganizationLogoUpload}
                requestStatus={organizationLogoRequestStatus}
                required={false}
              >
                <Label tooltipContent="Logo must have a width AND height of at least 500 pixels. Format must be either JPG or PNG, and file size cannot exceed 2 megabytes.">
                  Organisation logo
                </Label>
              </ImageUpload>
            </div>
          </Route>

          <div>
            <Label htmlFor="benefit">Main community benefit</Label>

            <select
              id="benefit"
              className="select block"
              value={organization.benefit}
              onChange={(evt: any) => setOrganization({ ...organization, benefit: evt.target.value })}
              required
            >
              <option value="">Select one &or;</option>

              {Object.keys(benefitToReadableString).map(key => (
                <option value={key} key={key}>
                  {
                    // @ts-ignore
                    benefitToReadableString[key]
                  }
                </option>
              ))}
            </select>
          </div>

          <div>
            <Label htmlFor="description">Description of your organisation</Label>

            <textarea
              id="description"
              className="textarea"
              placeholder="Provide a brief description about your organization, who you are, what you bring to the community, why these funds are important, how they will be spent, who will be benefiting from these donations"
              maxLength={500}
              value={organization.description}
              onChange={(evt: any) => setOrganization({ ...organization, description: evt.target.value })}
              required
            />
            <p>{organization.description.length}/500 characters</p>
          </div>
        </div>

        <Route exact path="/charity">
          <div className="relative ml-lg">
            <img src={logoAppPreview} alt="logo preview in app" className="w-18 min-w-18" />

            {organizationLogoRequestStatus === 'loading' ? (
              <div className="centerAbsolute">
                <div className="loadingSpinner -mt-md -ml-md" />
              </div>
            ) : (
              <img
                src={uiFields.organizationLogoUrl}
                className="w-6 h-6 rounded border-2 border-red centerAbsolute"
                style={{ objectFit: 'cover' }}
                alt="logo preview"
              />
            )}

            <p className="text-lg absolute top-0 left-1/2 transform -translate-x-1/2">
              <b>Logo preview</b>
            </p>
          </div>
        </Route>
      </div>
    </Accordion>
  )
}
