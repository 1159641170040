import jwtDecode from 'jwt-decode'
import { logError } from './logError'
import { authentication } from './authentication'

export function getUserIdFromJwt(_token: string = authentication.getToken()): string {
  try {
    const { sub } = jwtDecode<any>(_token)
    return sub
  } catch (error: any) {
    logError(new Error('Could not extract userId from JWT'), error)
    return 'Unknown'
  }
}
