import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { extendCampaign } from '../../services'
import { Dialog, Datepicker, Label, CloseIcon, CheckmarkIcon, TimeIcon } from '../shared'
import { addMonthsToDate, logError } from '../../lib'

function campaignExpiresSoon(endTime: string) {
  const aboutOneMonth = 86400000 * 28
  return new Date(endTime).getTime() - Date.now() < aboutOneMonth
}

const statusToReadableMap = {
  NEW: 'Pending approval',
  ACTIVE: 'Approved',
  STOPPED: 'Stopped',
  EXPIRED: 'Expired'
}

const DialogContainer = styled.div`
  > svg {
    width: 100%;
  }
`

type Props = {
  charityId: string
  campaignSummary: CampaignSummaryWithChangesIndicator
}

export const CampaignRow = ({ charityId, campaignSummary }: Props) => {
  const { campaignId, name, status, startTime, endTime, hasPendingChanges } = campaignSummary
  const history = useHistory()
  const [showExtendDialog, setShowExtendDialog] = useState(false)
  const [extendRequestStatus, setExtendRequestStatus] = useState<RequestStatus | 'validationFailed'>('initial')
  const minEndDate = addMonthsToDate(new Date(endTime), 1)
  const maxEndDate = addMonthsToDate(new Date(endTime), 6)
  const [newEndDate, setNewEndDate] = useState(maxEndDate)

  const _extendCampaign = async () => {
    try {
      setExtendRequestStatus('loading')
      await extendCampaign(charityId, campaignId, newEndDate)
      setExtendRequestStatus('success')

      setTimeout(() => {
        setShowExtendDialog(false)
        window.location.reload()
      }, 2500)
    } catch (error: any) {
      error.status === 400 ? setExtendRequestStatus('validationFailed') : setExtendRequestStatus('failed')
      logError(new Error('Failed to extend campaign'), error)
    }
  }

  return (
    <tr className="animate-fadeIn bg-fog">
      <td />
      <td>{name}</td>
      <td>{statusToReadableMap[status]}</td>
      <td>{hasPendingChanges ? 'Pending' : ' - '}</td>
      <td>
        {new Date(startTime).toLocaleDateString()} - {new Date(endTime).toLocaleDateString()}
      </td>
      <td>
        <div className="flex space-x-md">
          {status === 'ACTIVE' && campaignExpiresSoon(endTime) && (
            <button className="btn" onClick={() => setShowExtendDialog(true)}>
              Extend duration
            </button>
          )}
          <button className="btn" onClick={() => history.push(`/charity/${charityId}/campaign/${campaignId}`)}>
            View
          </button>
        </div>
      </td>

      {showExtendDialog && (
        <Dialog>
          <DialogContainer className="card max-w-25 space-y-lg p-xl animate-slideIn relative">
            {extendRequestStatus === 'success' ? (
              <React.Fragment>
                <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
                <h1 className="text-lg text-center">Your campaign has been successfully updated</h1>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  aria-label="Close"
                  className="btn btn-icon absolute top-lg right-lg"
                  onClick={() => setShowExtendDialog(false)}
                >
                  <CloseIcon width="1rem" height="1rem" />
                </button>

                <TimeIcon width="4rem" height="4rem" />

                <h1 className="text-xl text-center">Extend your campaign</h1>

                <p>
                  Click UPDATE to extend your campaign for an additional 6 months OR select the date yourself then press
                  UPDATE for a shorter extension.
                </p>

                <p>Any issues or queries, please contact donations.nsw@tomra.com.</p>

                <div>
                  <Label htmlFor="datepicker">The campaign will now end on:</Label>
                  <Datepicker
                    id="datepicker"
                    value={newEndDate}
                    onChange={setNewEndDate}
                    min={minEndDate}
                    max={maxEndDate}
                    required
                  />
                </div>

                <button
                  aria-disabled={extendRequestStatus === 'loading'}
                  disabled={extendRequestStatus === 'loading'}
                  className="btn btn-success w-full"
                  onClick={_extendCampaign}
                >
                  {extendRequestStatus === 'loading' ? <div className="loadingSpinner" /> : <span>Update</span>}
                </button>

                {extendRequestStatus === 'failed' && (
                  <p className="text-red">
                    Something went wrong. Please try again. If the issue persists, please contact us at
                    donations.nsw@tomra.com.
                  </p>
                )}

                {extendRequestStatus === 'validationFailed' && (
                  <p className="text-red">New end date must be between 1 and 6 months from the original end date.</p>
                )}
              </React.Fragment>
            )}
          </DialogContainer>
        </Dialog>
      )}
    </tr>
  )
}
