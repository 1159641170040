import React from 'react'
import { Accordion } from '../Accordion'

type Props = {
  uiFields: CharityUiFields
  setUiFields: (uiFields: CharityUiFields) => void
}

export const TermsAndConditions = ({ uiFields, setUiFields }: Props) => (
  <Accordion title="Terms and conditions">
    <div className="prose">
      <h2>Organisations seeking to partner with TOMRA and raise funds through the Return and Earn scheme must:</h2>

      <ol className="list-disc">
        <li>Be an organisation that can demonstrate a broad community benefit in New South Wales;</li>

        <li>
          Fulfill at least one of the following eligibility criteria:
          <ol className="list-decimal list-inside ml-lg">
            <li>
              Be currently registered as a charity in NSW (unless exempted under the Charitable Fundraising Act 1991);
              or
            </li>
            <li>Have Deductible Gift Status with the Australian Taxation Office; or</li>
            <li>Be a registered school (whether primary or secondary, government or private); or</li>
            <li>
              Be a sporting club or other community, educational or environmental organisation, be incorporated as a
              not-for-profit organisation (either under the Associations Incorporation Act 2009 or as a company limited
              by guarantee) and further, if a sporting club, demonstrate an affiliation with a recognised state sporting
              organisation;
            </li>
          </ol>
        </li>

        <li>Have been operating for at least two years;</li>

        <li>
          Demonstrate a willingness to use your own networks and resources to promote awareness of your partnership,
          your presence on the Return and Earn app, your fundraising campaign with the scheme to mutual advantage.
        </li>
        <li>Be reputable and of good standing in the community.</li>
        <li>
          Not be a political party or an organisation associated with current political issues or political activism.
        </li>
        <li>
          Not be associated with gambling, wagering, illegal drugs, tobacco or smoking as a primary source of their
          income.
        </li>
        <li>Not be associated with any illegal or criminal activity.</li>
        <li>
          Not be associated with any activity or publicity that could bring the schemes or any of its stakeholders into
          controversy or disrepute.
        </li>
      </ol>
    </div>

    <div>
      <a href="/tos" target="_blank" className="font-bold underline">
        Click here to read the full terms and conditions
      </a>

      <div className="flex items-center">
        <input
          id="tos"
          type="checkbox"
          className="checkbox mr-sm"
          checked={uiFields.hasAcceptedTos}
          onChange={evt => setUiFields({ ...uiFields, hasAcceptedTos: evt.target.checked })}
          required
        ></input>
        <label className="text-red flex-auto" htmlFor="tos">
          I have read and agree to the terms and conditions
        </label>
      </div>
    </div>
  </Accordion>
)
