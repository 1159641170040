export function addMonthsToDate(date: Date, months: number) {
  const day = date.getDate()
  const newDate = new Date(date)
  newDate.setMonth(date.getMonth() + months)

  if (newDate.getDate() !== day) {
    newDate.setDate(0)
  }

  return newDate
}
