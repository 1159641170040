import React from 'react'
import styled from 'styled-components'
import { Label, InfoTooltip, Datepicker, Accordion } from '../'
import { legalStructureToReadableString } from './enumsToReadableStrings'

const oneYearInMs = 31536000000

const Select = styled.select`
  > option:first-child {
    display: none;
  }
`

const RadioContainer = styled.div`
  > div {
    display: flex;
    align-items: center;

    input {
      margin-right: var(--spacing-md);
    }
  }

  hr {
    border: 1px solid var(--colors-fog);
  }
`

type Props = {
  uiFields: CharityUiFields
  setUiFields: (uiFields: CharityUiFields) => void
  organization: Organization
  setOrganization: (organization: Organization) => void
}

export const Eligibility = ({ uiFields, setUiFields, organization, setOrganization }: Props) => {
  const stateToMaxDateMap: any = {
    NSW: new Date(Date.now() - 2 * oneYearInMs),
    QLD: new Date(Date.now() - 2 * oneYearInMs),
    NT: new Date(Date.now() - 2 * oneYearInMs),
    VIC: new Date(Date.now() - 2 * oneYearInMs),
    TAS: new Date(Date.now() - 2 * oneYearInMs),
    ACT: new Date(Date.now() - 2 * oneYearInMs),
    WA: new Date(Date.now() - 2 * oneYearInMs)
  }

  const maxDate = stateToMaxDateMap[organization.operatingState] || new Date()

  const onStateSelect = (evt: any) => {
    const newState = evt.target.value
    setOrganization({
      ...organization,
      operatingState: newState,
      establishedDate: stateToMaxDateMap[newState]
    })
  }

  return (
    <Accordion title="Eligibility">
      <div>
        <Label htmlFor="operatingState">Which state is your organisation registered at?</Label>

        <Select
          id="operatingState"
          className="select w-min"
          value={organization.operatingState}
          onChange={onStateSelect}
          required
        >
          <option value="">Select one &or;</option>
          <option value="NSW">NSW</option>
          <option value="NT">NT</option>
          <option value="QLD">QLD</option>
          <option value="VIC">VIC</option>
          <option value="TAS">TAS</option>
          <option value="ACT">ACT</option>
          <option value="WA">WA</option>
        </Select>
      </div>

      <div>
        <Label
          htmlFor="establishedDate"
          tooltipContent="To be eligible, your organisation must have been operating for 2 years"
        >
          When did your organisation start operating?
        </Label>

        <Datepicker
          id="establishedDate"
          value={new Date(organization.establishedDate)}
          disabled={!organization.operatingState}
          aria-disabled={!organization.operatingState}
          onChange={date => setOrganization({ ...organization, establishedDate: date })}
          max={maxDate}
          required
        />
      </div>

      <RadioContainer
        className="space-y-md"
        onClick={(evt: any) => setOrganization({ ...organization, legalStructure: evt.target.value })}
      >
        <Label>What Legal structure best describes your not-for-profit organisation?</Label>

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure1"
            value="ACNC_CHARITY"
            name="legalStructure"
            checked={organization.legalStructure === 'ACNC_CHARITY'}
            required
            readOnly
          />
          <label htmlFor="legalStructure1">{legalStructureToReadableString['ACNC_CHARITY']}</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure2"
            value="REGISTERED_SCHOOL"
            name="legalStructure"
            checked={organization.legalStructure === 'REGISTERED_SCHOOL'}
            required
            readOnly
          />
          <label htmlFor="legalStructure2">{legalStructureToReadableString['REGISTERED_SCHOOL']}</label>
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure3"
            value="NON_PROFIT_UNDER_AIA2009"
            name="legalStructure"
            checked={organization.legalStructure === 'NON_PROFIT_UNDER_AIA2009'}
            required
            readOnly
          />
          <label htmlFor="legalStructure3">{legalStructureToReadableString['NON_PROFIT_UNDER_AIA2009']}</label>
          <InfoTooltip content="Note that sporting clubs must be affiliated with a recognised state sporting organisation" />
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure4"
            value="NON_PROFIT_COMPANY"
            name="legalStructure"
            checked={organization.legalStructure === 'NON_PROFIT_COMPANY'}
            required
            readOnly
          />
          <label htmlFor="legalStructure4">{legalStructureToReadableString['NON_PROFIT_COMPANY']}</label>
          <InfoTooltip content="Note that sporting clubs must be affiliated with a recognised state sporting organisation" />
        </div>

        <hr />

        <div>
          <input
            type="radio"
            className="radio"
            id="legalStructure5"
            value="OTHER"
            name="legalStructure"
            checked={organization.legalStructure === 'OTHER'}
            required
            readOnly
          />
          <label htmlFor="legalStructure5">{legalStructureToReadableString['OTHER']}</label>
        </div>

        {organization.legalStructure !== 'OTHER' && <hr />}
      </RadioContainer>

      {organization.legalStructure === 'OTHER' && (
        <React.Fragment>
          <div>
            <Label htmlFor="legalStructureDetails">Please specify</Label>

            <input
              type="text"
              className="max-w-30"
              id="legalStructureDetails"
              value={organization.legalStructureDetails}
              onChange={evt => setOrganization({ ...organization, legalStructureDetails: evt.target.value })}
              required
            />
          </div>

          <hr />
        </React.Fragment>
      )}

      <div className="flex items-center">
        <input
          id="operatesInNSW"
          type="checkbox"
          className="checkbox mr-sm"
          checked={uiFields.operatesInNSW}
          onChange={evt => setUiFields({ ...uiFields, operatesInNSW: evt.target.checked })}
          required
        ></input>
        <label htmlFor="operatesInNSW" className="flex-auto text-red">
          By ticking this box you are confirming that you currently operate in NSW
        </label>
      </div>
    </Accordion>
  )
}
