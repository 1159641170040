import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchCharities } from '../../services'
import { CharityRow } from './CharityRow'
import { logError } from '../../lib'

export const CharitiesList = () => {
  const [existingCharities, setExistingCharities] = useState<CharitySummaryWithChangesIndicator[]>([])
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('loading')
  const history = useHistory()

  useEffect(() => {
    fetchCharities().then(
      (charities: CharitySummaryWithChangesIndicator[]) => {
        if (charities.length === 0) {
          history.push('/charity')
        }

        setExistingCharities(charities)
        setRequestStatus('initial')
      },
      error => {
        setRequestStatus('failed')
        logError(new Error('Failed to load existing charities'), error)
      }
    )
  }, [setExistingCharities, history])

  return requestStatus === 'loading' ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" data-testid="loading" />
    </div>
  ) : requestStatus === 'failed' ? (
    <h1 className="centerAbsolute alert alert-danger">
      Failed to load your charity organisations. Please try again later.
    </h1>
  ) : (
    <div className="card flex flex-col m-lg max-w-screen-2xl mx-auto">
      <h1 className="text-xl m-lg">Your charities</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Charity</th>
            <th>Campaign</th>
            <th>Status</th>
            <th>Changes status</th>
            <th>Starts - Ends</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {existingCharities.map(charitySummary => (
            <CharityRow key={charitySummary.id} charitySummary={charitySummary} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
