import React from 'react'
import styled from 'styled-components'
import { InfoIcon } from '../shared'

const Container = styled.div<{ content: string }>`
  :last-child {
    margin-left: var(--spacing-sm);
  }

  :hover:after {
    background-color: var(--colors-midnight);
    border-radius: var(--radius-half);
    padding: var(--spacing-md);
    box-shadow: var(--shadow-lg);
    content: '${props => props.content}';
    color: white;
    font-size: 0.75rem;
    position: absolute;
    bottom: 25px;
    left: 25px;
    min-width: 200px;
    z-index: 100;
  }
`

type Props = {
  content: string
}

export const InfoTooltip = ({ content }: Props) => (
  <Container className="relative inline-block w-min" content={content} onClick={evt => evt.stopPropagation()}>
    <InfoIcon width="1rem" height="1rem" />
  </Container>
)
