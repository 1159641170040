import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CampaignRow } from '../campaigns/CampaignRow'
import { fetchCampaigns } from '../../services'
import { logError } from '../../lib'

const statusToReadableMap = {
  NEW: 'Awaiting campaign',
  ACTIVE: 'Active',
  DISABLED: 'Disabled'
}

type Props = {
  charitySummary: CharitySummaryWithChangesIndicator
}

export const CharityRow = ({ charitySummary }: Props) => {
  const [existingCampaigns, setExistingCampaigns] = useState<CampaignSummaryWithChangesIndicator[]>([])
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('loading')
  const history = useHistory()
  const { displayName, status, id, hasPendingChanges } = charitySummary

  useEffect(() => {
    fetchCampaigns(id).then(
      (campaignSummary: CampaignSummaryWithChangesIndicator[]) => {
        setExistingCampaigns(campaignSummary)
        setRequestStatus('initial')
      },
      (error: any) => {
        setRequestStatus('failed')
        logError(new Error('Failed to load existing charity'), error)
      }
    )
  }, [setExistingCampaigns, id])

  const hasActiveCampaign = existingCampaigns.find(
    campaign => campaign.status === 'ACTIVE' || campaign.status === 'NEW'
  )

  return (
    <React.Fragment>
      <tr>
        <td>{displayName}</td>
        {hasActiveCampaign ? <td>&#8681;</td> : <td />}
        <td>{statusToReadableMap[status]}</td>
        <td>{hasPendingChanges ? 'Pending' : ' - '}</td>
        <td />
        <td>
          <span className="flex items-center space-x-md">
            {!hasActiveCampaign && requestStatus !== 'loading' && (
              <Link to={`/charity/${id}/campaign`}>
                <button className="btn btn-primary-dark">Create campaign</button>
              </Link>
            )}
            <button className="btn" onClick={() => history.push(`/charity/${id}`)}>
              View
            </button>
          </span>
        </td>
      </tr>

      {requestStatus === 'failed' ? (
        <tr>
          <td className="text-red text-lg font-bold p-lg">Failed to fetch accompanying campaigns.</td>
        </tr>
      ) : (
        existingCampaigns.length > 0 &&
        existingCampaigns.map(campaignSummary => (
          <CampaignRow key={campaignSummary.campaignId} charityId={id} campaignSummary={campaignSummary} />
        ))
      )}
    </React.Fragment>
  )
}
