import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Label, Datepicker, Dialog, CheckmarkIcon } from '../shared'
import { logError, addMonthsToDate } from '../../lib'
import { submitCampaign, fetchCampaign, updateCampaign } from '../../services'
import { Campaign } from '../../types'

const newCampaignId = uuidv4()
const twoWeeksInMs = 1209600000
const minStartDate = new Date(Date.now() + twoWeeksInMs)

const formatFinancialTargetDisplayValue = (value: number) => {
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0
  })

  return formatter.format(value)
}

const campaignInit: Campaign = {
  campaignId: newCampaignId,
  name: '',
  description: '',
  startTime: minStartDate,
  endTime: addMonthsToDate(minStartDate, 12),
  financialTarget: '',
  engagementPlan: '',
  createdAt: ''
}

export const CampaignForm = () => {
  const history = useHistory()
  const [campaign, setCampaign] = useState<Campaign>(campaignInit)
  const [fetchRequestStatus, setFetchRequestStatus] = useState<RequestStatus>('initial')
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const { charityId: pathCharityId, campaignId: pathCampaignId } = useParams<{
    charityId: string
    campaignId?: string
  }>()
  const [campaignReference, setCampaignReference] = useState<Campaign | null>(null)

  const _fetchCampaign = useCallback(async (charityId, campaignId) => {
    try {
      setFetchRequestStatus('loading')
      const campaign = await fetchCampaign(charityId, campaignId)
      setCampaignReference(campaign)
      setCampaign(campaign)
      setFetchRequestStatus('success')
    } catch (error: any) {
      logError(new Error('Failed to fetch existing campaign'), error)
      setFetchRequestStatus('failed')
    }
  }, [])

  const onSubmit = async (evt: any) => {
    evt.preventDefault()

    if (pathCampaignId) {
      return updateExistingCampaign()
    } else {
      return createNewCampaign()
    }
  }

  const createNewCampaign = async () => {
    try {
      setSubmitRequestStatus('loading')
      await submitCampaign(pathCharityId, campaign)
      setSubmitRequestStatus('success')
    } catch (error: any) {
      setSubmitRequestStatus('failed')
      logError(new Error('Failed to create new campaign'), error)
    }
  }

  const updateExistingCampaign = async () => {
    try {
      setSubmitRequestStatus('loading')
      await updateCampaign(pathCharityId, campaign)
      setSubmitRequestStatus('success')
    } catch (error: any) {
      setSubmitRequestStatus('failed')
      logError(new Error('Failed to update new campaign'), error)
    }
  }

  const campaignHasChanges = useMemo(() => {
    if (!campaignReference || !pathCampaignId) {
      return false
    }

    return JSON.stringify(campaignReference) === JSON.stringify(campaign)
  }, [campaign, campaignReference, pathCampaignId])

  useEffect(() => {
    if (pathCampaignId) {
      _fetchCampaign(pathCharityId, pathCampaignId)
    }
  }, [_fetchCampaign, pathCharityId, pathCampaignId])

  return fetchRequestStatus === 'loading' ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" data-testid="loading" />
    </div>
  ) : fetchRequestStatus === 'failed' ? (
    <h1 className="centerAbsolute alert alert-danger">Something went wrong. Please try again later</h1>
  ) : (
    <form onSubmit={onSubmit} className="card space-y-xl mx-auto my-lg p-lg max-w-screen-sm">
      <h1 className="text-xl">Your fundraising campaign</h1>

      <hr />

      <div>
        <Label htmlFor="name">Campaign name</Label>

        <input
          type="text"
          id="name"
          value={campaign.name}
          onChange={evt => setCampaign({ ...campaign, name: evt.target.value })}
          maxLength={60}
          required
        />
        <p>{campaign.name.length}/60 characters</p>
      </div>

      <div>
        <Label
          htmlFor="description"
          tooltipContent="To help supporters engage with your campaign, be specific, transparent and make it relevant to your community. What are you raising money for? How will the funds raised will benefit your community?"
        >
          Description
        </Label>

        <textarea
          id="description"
          placeholder="Example: Our organisation is raising funds to purchase items for the care and comfort of patients of our local Hospital, including Maternity, emergency and operating theatre patients."
          maxLength={500}
          value={campaign.description}
          onChange={evt => setCampaign({ ...campaign, description: evt.target.value })}
          required
        />
        <p>{campaign.description.length}/500 characters</p>
      </div>

      <div>
        <Label
          htmlFor="startTime"
          tooltipContent="Setting a visible deadline is a powerful motivator for donors. But allow a reasonable amount of time to achieve your goals. Your deadline, too, should be ambitious but attainable."
        >
          Start date
        </Label>

        <Datepicker
          id="startTime"
          value={new Date(campaign.startTime)}
          onChange={date => {
            if (date) {
              setCampaign({ ...campaign, startTime: date, endTime: addMonthsToDate(date, 1) })
            }
          }}
          min={minStartDate}
          required
        />
      </div>

      <div>
        <Label
          htmlFor="endTime"
          tooltipContent="Minimum 3 months and maximum 1 year from the date you want to start your fundraising."
        >
          End date
        </Label>

        <Datepicker
          id="endTime"
          value={new Date(campaign.endTime)}
          onChange={date => setCampaign({ ...campaign, endTime: date })}
          min={addMonthsToDate(new Date(campaign.startTime), 1)}
          max={addMonthsToDate(new Date(campaign.startTime), 12)}
          required
        />
      </div>

      <div>
        <Label
          htmlFor="financialTarget"
          tooltipContent="Set a target that is both Ambitious and Attainable (min $100 and max $1 mill). It will keep you motivated and will inspire your supporters and the community to help you reach it, just that little bit more. And remember one eligible bottle or can donated is worth 10 cents so set a target that is challenging, but not impossible to reach."
        >
          Financial target
        </Label>

        <input
          type="text"
          id="financialTarget"
          value={campaign.financialTarget}
          onFocus={() => setCampaign({ ...campaign, financialTarget: campaign.financialTarget.replace(/\D/g, '') })}
          onChange={evt => setCampaign({ ...campaign, financialTarget: evt.target.value.replace(/\D/g, '') })}
          onBlur={evt =>
            setCampaign({
              ...campaign,
              financialTarget: formatFinancialTargetDisplayValue(Number(evt.target.value))
            })
          }
          required
        />

        {/* Just a hack for native form validation due to the currency formatting trickery above */}
        <input
          type="number"
          value={String(campaign.financialTarget).replace(/\D/g, '')}
          min={100}
          max={1000000}
          style={{ height: '1px', width: '1px', padding: 0, border: 'none' }}
          aria-hidden="true"
          disabled
          required
        />
      </div>

      <div>
        <Label htmlFor="engagementPlan">Awareness and Engagement plan</Label>

        <textarea
          id="engagementPlan"
          className="textarea"
          placeholder="List activities that will raise awareness of your fundraising campaign, describe how you will engage with all your networks (staff, volunteers, supporters, local businesses, corporate donors, ...) and also engage the wider community to maximise the donations."
          maxLength={1000}
          value={campaign.engagementPlan}
          onChange={evt => setCampaign({ ...campaign, engagementPlan: evt.target.value })}
          required
        />
        <p>{campaign.engagementPlan.length}/1000 characters</p>
      </div>

      <div className="grid grid-cols-12 gap-x-md">
        <button type="button" className="btn col-span-3 col-start-7" onClick={() => history.push('/')}>
          Cancel
        </button>

        <button
          className="btn btn-primary-dark col-span-3"
          disabled={submitRequestStatus === 'loading' || campaignHasChanges}
          aria-disabled={submitRequestStatus === 'loading'}
        >
          {submitRequestStatus === 'loading' ? (
            <div className="loadingSpinner" aria-label="Please wait" />
          ) : (
            <span>{pathCampaignId ? 'Update' : 'Submit'}</span>
          )}
        </button>
      </div>

      {submitRequestStatus === 'failed' && <p className="text-red mt-sm">Something went wrong. Please try again.</p>}

      {submitRequestStatus === 'success' && (
        <Dialog>
          <div className="card flex flex-col items-center p-xl animate-fadeIn">
            <CheckmarkIcon width="4rem" height="4rem" color="var(--colors-green)" />
            <h1 className="text-xl">Great!</h1>
            <p className="my-lg">
              {pathCampaignId
                ? 'Your changes has been submitted and should be processed within 10 business days.'
                : 'Your campaign has been submitted and should be processed within 10 business days.'}
            </p>
            <button className="btn" onClick={() => history.push('/')}>
              Close
            </button>
          </div>
        </Dialog>
      )}
    </form>
  )
}
