import React, { useState } from 'react'
import styled from 'styled-components'
import { CheckmarkIcon, MinusIcon, PlusIcon } from '../shared'

const Fieldset = styled.fieldset<{ locked?: boolean }>`
  position: relative;
  opacity: ${props => (props.locked ? 0.7 : 1)};
  cursor: ${props => (props.locked ? 'not-allowed' : 'initial')};
  pointer-events: ${props => (props.locked ? 'none' : 'initial')};

  :valid h2 button .checkmark-icon {
    opacity: 1;
  }

  :invalid h2 button .checkmark-icon {
    opacity: 0;
  }
`

type Props = {
  title: string
  locked?: boolean
  children: React.ReactNode
}

export const Accordion = ({ children, locked, title }: Props) => {
  const [open, setOpen] = useState(false)
  // Generate a randomish number between 0 and 1000 to make accordion header and panel id unique
  const index = Math.floor(Math.random() * 1000)

  return (
    <Fieldset className="card flex flex-col" locked={locked}>
      <h2>
        <button
          id={`accordion-header-${index}`}
          aria-expanded={open}
          aria-controls={`accordion-panel-${index}`}
          className="p-lg capitalize w-full"
          onClick={(evt: React.MouseEvent) => {
            evt.preventDefault()
            setOpen(!open)
          }}
        >
          <span className="flex items-center text-lg">
            <div className="checkmark-icon mr-md">
              <CheckmarkIcon width="2rem" height="2rem" color="var(--colors-green)" />
            </div>
            <span className="flex-1 text-left">{title}</span>
            {open ? <MinusIcon width="2rem" height="2rem" /> : <PlusIcon width="2rem" height="2rem" />}
          </span>
        </button>
      </h2>

      <div
        id={`accordion-panel-${index}`}
        aria-labelledby={`accordion-header-${index}`}
        className={`space-y-xl ${open ? 'h-min p-lg' : 'h-0 overflow-hidden'}`}
      >
        {children}
      </div>
    </Fieldset>
  )
}
