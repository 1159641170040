import React from 'react'
import styled from 'styled-components'
import { FileUploadIcon } from '../shared'

const HiddenInput = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
`

type Props = {
  children: React.ReactNode
  uploadedFileName: string | undefined
  onChange: (file: File) => void
  requestStatus: RequestStatus
  id: string
  acceptedFileTypes?: string
  errorMsg?: string
  required?: boolean
}

export const FileUpload = ({
  children,
  acceptedFileTypes,
  uploadedFileName,
  onChange,
  requestStatus,
  id,
  errorMsg,
  required
}: Props) => (
  <div>
    {children}

    <label htmlFor={id} data-testid={id} className="btn flex items-center space-x-md w-max">
      <FileUploadIcon width="1rem" height="1rem" />
      {requestStatus === 'loading' ? (
        <div className="loadingSpinner" aria-label="Please wait" />
      ) : (
        <span>{uploadedFileName || 'Click to upload'}</span>
      )}
    </label>

    {requestStatus === 'failed' || errorMsg ? (
      <span className="text-red ml-sm">{errorMsg ? errorMsg : 'Something went wrong. Please try again.'}</span>
    ) : null}

    <HiddenInput
      name={id}
      type="file"
      id={id}
      accept={acceptedFileTypes || 'application/pdf'}
      onClick={evt => {
        // need to reset the input value, or else onChange
        // wont fire upon selecting the same image multiple times
        const element = evt.target as HTMLInputElement
        element.value = ''
      }}
      onChange={(evt: any) => evt.target.files[0] && onChange(evt.target.files[0])}
      required={required !== undefined ? required : !uploadedFileName}
    />
  </div>
)
