import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { App } from './App'
import { authentication } from './lib'
import '@tomra/design-system/src/config/tailwind.config.css'
import { TermsAndConditions } from './components/TermsAndConditions'

const Index = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<'toBeDecided' | 'yes'>('toBeDecided')

  useEffect(() => {
    authentication.init().then(
      () => setIsAuthenticated('yes'),
      () => authentication.login()
    )
  }, [])

  if (isAuthenticated !== 'yes') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path="/tos" component={TermsAndConditions} />
          <Route component={App} />
        </Switch>
      </BrowserRouter>
    </React.StrictMode>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))
