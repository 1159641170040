import { v4 as uuidv4 } from 'uuid'
import { KEYCLOAK_HOST } from './environment'
import {
  getItemFromWebStorage,
  setItemInWebStorage,
  removeItemFromWebStorage,
  getQueryParameterByName,
  logError,
  HttpError
} from './'

// When doing the _performCodeRequest(), the redirect_uri passed in that request
// must be identical to the redirect_uri query param used when doing impersonation through KC
// (see redirectUponImpersonate() method in KC account theme scripts)
const REDIRECT_URI = encodeURIComponent(window.location.origin)

export class AuthStore {
  _accessToken: string = ''
  _refreshToken: string = ''
  _realm: string
  _clientId: string
  _keycloakHost: string

  constructor(realm: string, clientId: string, keycloakHost: string) {
    this._realm = realm
    this._clientId = clientId
    this._keycloakHost = keycloakHost
  }

  _performCodeRequest = (code: string) => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `code=${code}&client_id=${this._clientId}&redirect_uri=${REDIRECT_URI}&grant_type=authorization_code`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to exchange auth code for tokens')
    })
  }

  _performTokenRequest = (refreshToken = this._refreshToken) => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `refresh_token=${refreshToken}&client_id=${this._clientId}&grant_type=refresh_token`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to refresh token')
    })
  }

  initWithCode = async (code: string) => {
    try {
      const { access_token, refresh_token } = await this._performCodeRequest(code)

      this._accessToken = access_token
      this._refreshToken = refresh_token

      setItemInWebStorage('refreshToken', refresh_token)

      // Strip the URL of query params
      window.history.replaceState({}, document.title, window.location.pathname)
    } catch (error: any) {
      logError(new Error('Failed to initialise auth with code'), error)
      // Throw so that init() properly rejects
      throw error
    }
  }

  initWithRefreshToken = async (refreshToken: string = this._refreshToken) => {
    try {
      const { access_token } = await this._performTokenRequest(refreshToken)

      this._accessToken = access_token
      this._refreshToken = refreshToken
    } catch (error: any) {
      if (error.status === 400) {
        return Promise.reject('Unauthenticated')
      } else {
        logError(new Error('Failed to initialise auth with refresh token'), error)
        throw error
      }
    }
  }

  init = async () => {
    // Already authenticated
    if (this._accessToken && this._refreshToken) {
      return Promise.resolve()
    }

    const authCode = getQueryParameterByName('code')
    const refreshToken = await getItemFromWebStorage('refreshToken')

    if (authCode) {
      return this.initWithCode(authCode)
    } else if (refreshToken) {
      return this.initWithRefreshToken(refreshToken)
    } else {
      return Promise.reject('Unauthenticated')
    }
  }

  login = () => {
    const state = encodeURIComponent(uuidv4())
    const nonce = encodeURIComponent(uuidv4())
    const url = `${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/auth?client_id=${this._clientId}&redirect_uri=${REDIRECT_URI}&state=${state}&nonce=${nonce}&response_type=code&scope=openid`

    window.location.href = url
  }

  logout = async () => {
    await removeItemFromWebStorage('refreshToken')

    const url = `${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/logout?redirect_uri=${REDIRECT_URI}`

    window.location.href = url
  }

  goToAccountManagement = () => {
    const url = `${this._keycloakHost}/realms/${this._realm}/account`
    window.location.href = url
  }

  getToken = () => {
    return this._accessToken
  }

  getRefreshToken = () => {
    return this._refreshToken
  }

  fetchNewToken = async () => {
    const { access_token } = await this._performTokenRequest()
    this._accessToken = access_token
  }
}

export const authentication = new AuthStore('TomraCharityUsers', 'mytomra-charity-ui', KEYCLOAK_HOST)
