import React from 'react'
import styled from 'styled-components'
import { InfoTooltip, Label, Accordion } from '../'
import { geoFootprintToReadableString } from './enumsToReadableStrings'

const RadioContainer = styled.div`
  > div {
    display: flex;
    align-items: center;

    input {
      margin-right: var(--spacing-md);
    }
  }

  hr {
    border: 1px solid var(--colors-fog);
  }
`

type Props = {
  organization: Organization
  setOrganization: (organization: Organization) => void
}

export const Location = ({ organization, setOrganization }: Props) => (
  <Accordion title="Location and operational footprint">
    <div>
      <Label htmlFor="streetAddress">Street address</Label>

      <input
        type="text"
        id="streetAddress"
        maxLength={35}
        value={organization.streetAddress}
        onChange={evt => setOrganization({ ...organization, streetAddress: evt.target.value })}
        required
      />
      <p>{organization.streetAddress.length}/35 characters</p>
    </div>

    <div>
      <Label htmlFor="suburb">Suburb</Label>

      <input
        type="text"
        id="suburb"
        value={organization.suburb}
        onChange={evt => setOrganization({ ...organization, suburb: evt.target.value })}
      />
    </div>

    <div>
      <Label htmlFor="postCode">Postcode</Label>

      <input
        type="text"
        id="postCode"
        value={organization.postCode}
        onChange={evt => setOrganization({ ...organization, postCode: evt.target.value })}
        required
      />
    </div>

    <RadioContainer
      className="space-y-md"
      onClick={(evt: any) => setOrganization({ ...organization, geoFootprint: evt.target.value })}
    >
      <Label>Describe your operational footprint</Label>

      <div>
        <input
          type="radio"
          className="radio"
          id="geoFootprint1"
          value="LOCAL"
          name="geoFootprint"
          checked={organization.geoFootprint === 'LOCAL'}
          required
          readOnly
        />
        <label htmlFor="geoFootprint1">{geoFootprintToReadableString['LOCAL']}</label>
        <InfoTooltip content="Local must be a school, sports club, local not-for-profit organisation or a community group with a strong local presence that can demonstrate a broad community benefit, within the local area, in which a reverse vending machine(s) is located." />
      </div>

      <hr />

      <div>
        <input
          type="radio"
          className="radio"
          id="geoFootprint2"
          value="STATE"
          name="geoFootprint"
          checked={organization.geoFootprint === 'STATE'}
          required
          readOnly
        />
        <label htmlFor="geoFootprint2">{geoFootprintToReadableString['STATE']}</label>
      </div>

      <hr />

      <div>
        <input
          type="radio"
          className="radio"
          id="geoFootprint3"
          value="NATIONAL"
          name="geoFootprint"
          checked={organization.geoFootprint === 'NATIONAL'}
          required
          readOnly
        />
        <label htmlFor="geoFootprint3">{geoFootprintToReadableString['NATIONAL']}</label>
      </div>
    </RadioContainer>
  </Accordion>
)
