import React from 'react'
import ReactDOM from 'react-dom'

type Props = {
  children: React.ReactNode
}

export const Dialog = ({ children }: Props) => {
  return ReactDOM.createPortal(
    <div className="dialog-overlay items-center">{children}</div>,
    document.getElementById('root') as HTMLDivElement
  )
}
