export const legalStructureToReadableString = {
  ACNC_CHARITY: 'Is currently registered as a charity (unless exempted under the Charitable Fundraising Act 1991)',
  REGISTERED_SCHOOL: 'Is a registered school (whether primary or secondary, government or private) in New South Wales',
  NON_PROFIT_UNDER_AIA2009:
    'Is a sporting club, community, education or environmental organisation that is incorporated as a not-for-profit organisation under the Associations Incorporation Act 2009',
  NON_PROFIT_COMPANY:
    'Is a sporting club, community, education, or environmental organisation that is incorporated as a not-for-profit organisation as a company limited guarantee',
  OTHER: 'Other (ie. brigade constituted under the NSW Rural Fires Act 1997)'
}

export const geoFootprintToReadableString = {
  LOCAL: 'Local',
  STATE: 'State-wide',
  NATIONAL: 'National'
}

export const benefitToReadableString = {
  ANIMAL_WELFARE: 'Animal Welfare',
  ARTS_AND_CULTURE: 'Arts And Culture',
  COMMUNITY_DEVELOPMENT: 'Community Services',
  DISABILITY_SERVICES: 'Disability Services',
  DOMESTIC_AND_FAMILY: 'Domestic & Family violence',
  DROUGHT_AND_BUSHFIRE_RELIEF: 'Drought & Bushfire Relief',
  EDUCATION: 'Education',
  ENVIRONMENT: 'Environment',
  HEALTH: 'Health & Mental Health',
  HUMAN_AND_CIVIL_RIGHTS: 'Human and Civil rights',
  HUMAN_SERVICES: 'Human Services',
  INTERNATIONAL_AID: 'International Aid',
  RELIGION: 'Religion',
  RESEARCH_AND_PUBLIC_POLICY: 'Research & Public Policy',
  SPORTS: 'Sporting Group & Club',
  VETERANS_SERVICES: 'Veterans services',
  '': ''
}

export const dgrStatusToReadableString = {
  NONE: "My organisation doesn't have the DGR endorsement",
  UNRESTRICTED: 'My organisation has unrestricted DGR endorsement with the ATO',
  RESTRICTED: 'My organisation has restricted DGR endorsement with the ATO',
  RESTRICTED_DGR_ACTIVITIES_YES:
    'My organisation has restricted DGR endorsement with the ATO. The funds raised through Return and Earn will be spent exclusively on DGR related activities.',
  RESTRICTED_DGR_ACTIVITIES_NO:
    'My organisation has restricted DGR endorsement with the ATO. The funds raised through Return and Earn will not be spent exclusively on DGR related activities.',
  '': ''
}
