import React from 'react'
import { InfoTooltip } from './InfoTooltip'

type Props = {
  htmlFor?: string
  tooltipContent?: string
  children: React.ReactNode
}

export const Label = ({ htmlFor, tooltipContent, children }: Props) => (
  <div className="flex">
    <label htmlFor={htmlFor} className="block mb-xs font-bold">
      <small>
        <b>{children}</b>
      </small>
    </label>
    {tooltipContent && <InfoTooltip content={tooltipContent} />}
  </div>
)
