import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import { FileUpload, Label, Accordion } from '../'
import { fetchOrgNameByAbn, uploadFile } from '../../../services'
import { logError } from '../../../lib'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  setUiFields: (uiFields: CharityUiFields) => void
  organization: Organization
  setOrganization: (organization: Organization) => void
}

export const Details = ({ charityId, uiFields, setUiFields, organization, setOrganization }: Props) => {
  const [abnFileRequestStatus, setAbnFileRequestStatus] = useState<RequestStatus>('initial')
  const [incorporationFileRequestStatus, setIncorporationFileRequestStatus] = useState<RequestStatus>('initial')
  const [localGroupFileRequestStatus, setLocalGroupFileRequestStatus] = useState<RequestStatus>('initial')
  const [nameEditable, setNameEditable] = useState(false)
  const isUploading =
    abnFileRequestStatus === 'loading' ||
    incorporationFileRequestStatus === 'loading' ||
    localGroupFileRequestStatus === 'loading'

  const onAbnBlur = async () => {
    try {
      const { name } = await fetchOrgNameByAbn(organization.organizationAbn)
      setOrganization({ ...organization, name })
      setNameEditable(false)
    } catch (error: any) {
      setOrganization({ ...organization, name: '' })
      setNameEditable(true)
    }
  }

  const onAbnFileUpload = async (abnFile: File) => {
    setAbnFileRequestStatus('loading')

    try {
      await uploadFile(abnFile, charityId, 'ABNDocument')
      setAbnFileRequestStatus('initial')
      setUiFields({ ...uiFields, abnFileName: abnFile.name })
    } catch (error: any) {
      setAbnFileRequestStatus('failed')
      logError(new Error('Failed to upload ABNDocument file'), error)
    }
  }

  const onIncorporationFileUpload = async (incorporationFile: File) => {
    setIncorporationFileRequestStatus('loading')

    try {
      await uploadFile(incorporationFile, charityId, 'IncorporationFile')
      setIncorporationFileRequestStatus('initial')
      setUiFields({ ...uiFields, incorporationFileName: incorporationFile.name })
    } catch (error: any) {
      setIncorporationFileRequestStatus('failed')
      logError(new Error('Failed to upload IncorporationFile file'), error)
    }
  }

  const onLocalGroupFileUpload = async (localGroupNameFile: File) => {
    setLocalGroupFileRequestStatus('loading')

    try {
      await uploadFile(localGroupNameFile, charityId, 'ParentOrganizationLetter')
      setLocalGroupFileRequestStatus('initial')
      setUiFields({ ...uiFields, localGroupNameFileName: localGroupNameFile.name })
    } catch (error: any) {
      setLocalGroupFileRequestStatus('failed')
      logError(new Error('Failed to upload ParentOrganizationLetter file'), error)
    }
  }

  return (
    <Accordion title="Details Of Your Organisation" locked={isUploading}>
      <div>
        <Label htmlFor="organizationAbn">ABN</Label>

        <input
          type="text"
          id="organizationAbn"
          value={organization.organizationAbn}
          onChange={evt => setOrganization({ ...organization, organizationAbn: evt.target.value })}
          onBlur={onAbnBlur}
          required
        />
      </div>

      <div>
        <Label htmlFor="name">Organisation name</Label>

        <input
          type="text"
          id="name"
          value={organization.name}
          onChange={evt => setOrganization({ ...organization, name: evt.target.value })}
          disabled={!nameEditable}
          aria-disabled={!nameEditable}
          required
        />
      </div>

      <Route exact path="/charity">
        <FileUpload
          id="abnFile"
          uploadedFileName={uiFields.abnFileName}
          onChange={onAbnFileUpload}
          requestStatus={abnFileRequestStatus}
          acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
        >
          <Label>Please submit a recent copy of your ABN (Pdf)</Label>
        </FileUpload>
      </Route>

      {organization.legalStructure === 'NON_PROFIT_UNDER_AIA2009' && (
        <React.Fragment>
          <div>
            <Label htmlFor="incorporationNumber">Incorporation number</Label>

            <input
              type="text"
              id="incorporationNumber"
              value={organization.incorporationNumber}
              onChange={evt => setOrganization({ ...organization, incorporationNumber: evt.target.value })}
              required
            />
          </div>

          <Route exact path="/charity">
            <FileUpload
              id="incorporationFile"
              uploadedFileName={uiFields.incorporationFileName}
              onChange={onIncorporationFileUpload}
              requestStatus={incorporationFileRequestStatus}
              acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
            >
              <Label>Upload incorporation file (inc. association certificate)</Label>
            </FileUpload>
          </Route>
        </React.Fragment>
      )}

      <div>
        <input
          id="abnBelongsToParent"
          type="checkbox"
          className="checkbox inline-block"
          checked={uiFields.abnBelongsToParent}
          onChange={evt => setUiFields({ ...uiFields, abnBelongsToParent: evt.target.checked })}
        />

        <label htmlFor="abnBelongsToParent" style={{ marginLeft: 'var(--spacing-sm)' }}>
          Tick if the ABN provided above belongs to your parent organisation
        </label>
      </div>

      {uiFields.abnBelongsToParent && (
        <React.Fragment>
          <div>
            <Label htmlFor="localGroupName">Name of your local group</Label>

            <input
              type="text"
              id="localGroupName"
              value={organization.localGroupName}
              onChange={evt => setOrganization({ ...organization, localGroupName: evt.target.value })}
              required
            />
          </div>

          <Route exact path="/charity">
            <FileUpload
              id="localGroupNameFile"
              uploadedFileName={uiFields.localGroupNameFileName}
              onChange={onLocalGroupFileUpload}
              requestStatus={localGroupFileRequestStatus}
              acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
            >
              <div>
                <p>
                  If the ABN number stated above belongs to your parent organisation, you must provide a separate letter
                  signed by the parent body Head Office confirming that the local branch applying is:
                </p>

                <ul className="list-disc list-inside my-lg">
                  <li>An affiliated member of the parent organisation</li>
                  <li>Authorised to quote the ABN of its parent organisation</li>
                  <li>"not-for-profit" and authorised to fundraise</li>
                  <li>The contact name of the person applying is a legitimate member of the organisation</li>
                </ul>
              </div>
            </FileUpload>
          </Route>
        </React.Fragment>
      )}
    </Accordion>
  )
}
